import React from "react";

export default ({ title, className, noLine, children }) => {

  return (
    <div className={`text-center font-Coder-ExtraBold text-white text-3xl pt-3 pb-3`}>
    {title}
    <div
      style={{
        backgroundColor: "#505050",
        padding: 24,
        borderRadius: 24,
        border: 4,
        borderColor: "#ff1dec",
        borderStyle: "dashed",
        boxShadow: "rgba(0, 0, 0, 0.7) 0 5 11 2",
        height:"85%"
      }}
    >
      {children}
    </div>
    </div>
  );
};
