import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Button from "../components/common/Button";
import Modal from "../components/common/Modal";
import Card from "../components/common/Card";
import Spinner from "../components/common/Spinner";
import { initWeb3 } from "../utils.js";
import ApishToken from "../contracts/ApishToken.json";
import ApishStake from "../contracts/ApishStake.json";
import ApishPunks from "../contracts/ApishPunks.json";
import fromExponential from "from-exponential";
import {Link} from "react-router-dom";
const HomePage = (props) => {

  const [loading, setLoading] = useState(false);
  const [stakeLoading, setStakeLoading] = useState(false);
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [error, setError] = useState("");
  const [web3, setWeb3] = useState();
  const [accounts, setAccounts] = useState();
  const [apishStake, setApishStake] = useState();
  const [apishToken, setApishToken] = useState();

  const [totalSupply, setTotalSupply] = useState();
  const [balance, setBalance] = useState();
  const [totalStaked, setTotalStaked] = useState();
  const [stakes, setStakes] = useState();
  const [minStake, setMinStake] = useState();
  const [stakingTax, setStakingTax] = useState();
  const [unstakingTax, setUnstakingTax] = useState();
  const [dailyROI, setDailyROI] = useState();
  const [stakingRewards, setStakeRewards] = useState();
  const [totalRewards, setTotalRewards] = useState();
  const [amount, setAmount] = useState();
  const [unstakeAmount, setUnstakeAmount] = useState();
  const [totalDividends, setTotalDividends] = useState();
  const [dividends, setDividends] = useState();
  const [availableDividends, setAvailableDividends] = useState();
  const [showModal, setShowModal] = useState(false);

  const init = async () => {
    if (isReady()) {
      return;
    }

    setLoading(true); 
    let web3;
    try {
      web3 = await initWeb3();
    } catch (err) {
      console.error(err);
      setLoading(false);
      return;
    }

    const accounts = await web3.eth.getAccounts();
    const networkId = await web3.eth.net.getId();
    if (networkId !== 40) {
      setError("Connect to Telos EVM");
      setLoading(false);
      return;
    }

    const apishToken = new web3.eth.Contract(
      ApishToken.abi,
      "0x11FBfdf906d32753fA2A083dbd4Fb25C1094C6c4"
    );
    const totalSupply = await apishToken.methods.totalSupply().call();
    const balance = await apishToken.methods.balanceOf(accounts[0]).call();

    const apishStake = new web3.eth.Contract(
      ApishStake.abi,
      "0x5d6bb6115c2c9327b7ddb7802bc39e5991742f04"
    );
    const totalStaked = await apishStake.methods.totalStaked().call();
    const minStake = await apishStake.methods.minimumStakeValue().call();
    const stakingTax = await apishStake.methods.stakingTaxRate().call();
    const unstakingTax = await apishStake.methods.unstakingTaxRate().call();
    const dailyROI = await apishStake.methods.dailyROI().call();

    const apishPunks = new web3.eth.Contract(
       ApishPunks.abi,
       "0x54Bd4f7C2d606797f375272587c36DA2d249Ea6D"
     );


    setWeb3(web3);
    setAccounts(accounts);
    setApishStake(apishStake);
    setApishToken(apishToken);
    setTotalSupply(totalSupply);
    setBalance(balance);
    setTotalStaked(totalStaked);
    setMinStake(minStake);
    setStakingTax(stakingTax);
    setUnstakingTax(unstakingTax);
    setDailyROI(dailyROI);

    window.ethereum.on("accountsChanged", (accounts) => {
      setAccounts(accounts);
    });

    setLoading(false);
  };

  const isReady = () => {
    return !!apishStake && !!web3 && !!accounts;
  };

  useEffect(() => {
    const triggerAlreadyInjectedWeb3 = async () => {
      if (window.ethereum) {
        if (
          window.ethereum.selectedAddress &&
          window.ethereum.networkVersion === "1"
        ) {
          await init();
        }
      }
    };
    triggerAlreadyInjectedWeb3();
  }, []);

  async function updateAll() {
    await Promise.all([
      updateStakes(),
      updateTotalSupply(),
      updateAccountBalance(),
      updateTotalStaked(),
      stakeRewards(),
      totalReward(),
      totalDividend(),
      dividend(),
      availableDividendForClaim(),
    ]);
  }

  useEffect(() => {
    if (isReady()) {
      updateAll();
    }
  }, [apishStake, apishToken, web3, accounts]);

  async function updateStakes() {
    const stake = await apishStake.methods.stakes(accounts[0]).call();
    setStakes(stake);
    return stake;
  }

  async function updateAccountBalance() {
    if (apishToken) {
      const balance = await apishToken.methods.balanceOf(accounts[0]).call();
      setBalance(balance);
      return balance;
    }
  }

  async function updateTotalSupply() {
    if (apishToken) {
      const totalSupply = await apishToken.methods.totalSupply().call();
      setTotalSupply(totalSupply);
      return totalSupply;
    }
  }

  async function updateTotalStaked() {
    if (apishStake) {
      const totalStaked = await apishStake.methods.totalStaked().call();
      return totalStaked;
    }
  }

  async function stakeRewards() {
    if (apishStake) {
      const rewards = parseInt(
        await apishStake.methods.stakeRewards(accounts[0]).call()
      );
      const owing = parseInt(
        await apishStake.methods.calculateEarnings(accounts[0]).call()
      );
      const sum = rewards + owing;
      await setStakeRewards(sum);
      return sum;
    }
  }

  async function totalReward() {
    const owing = parseInt(
      await apishStake.methods.calculateEarnings(accounts[0]).call()
    );
    const recorded = parseInt(
      await apishStake.methods.stakeRewards(accounts[0]).call()
    );
    const sum = owing + recorded;
    await setTotalRewards(sum);
    return sum;
  }

  async function stake() {
      setStakeLoading(true);
      const actual = amount * (10 ** 9);
      const arg = fromExponential(actual);
      try {
        await apishToken.methods
          .approve("0x5d6bb6115c2c9327b7ddb7802bc39e5991742f04", arg)
          .send({ from: accounts[0] });

        await apishStake.methods.stake(arg).send({ from: accounts[0] });
        await updateAll();
      } catch (err) {
        if (err.code !== 4001) {
          setShowModal(true);
        }
        console.error(err);
      }
      setStakeLoading(false);
    }

  async function unstake() {
      if (parseFloat(stakes) === 0) {
        console.error("You don't have any staked APISH yet!");
        return;
      }
      setUnstakeLoading(true);
      const actual = unstakeAmount * (10 ** 9);
      const arg = fromExponential(actual);
      try {
        await apishStake.methods
          .unstake(arg)
          .send({ from: accounts[0] });
        await updateAll();
      } catch (err) {
        if (err.code !== 4001) {
          setShowModal(true);
        }
        console.error(err);
      }
      setUnstakeLoading(false);
    }

  async function withdrawEarnings() {
    if (parseFloat(totalRewards) === 0) {
      setShowModal(true);
      return;
    }
    setWithdrawLoading(true);
    try {
      await apishStake.methods.withdrawEarnings().send({ from: accounts[0] });
      await updateAll();
    } catch (err) {
      if (err.code !== 4001) {
        setShowModal(true);
      }
      console.error(err);
    }
    setWithdrawLoading(false);
  }

  // Apish Token
    async function totalDividend() {
        if (apishToken) {
          const totalDividends = await apishToken.methods.getTotalDividendsDistributed().call();
          setTotalDividends(totalDividends);
          return totalDividends;
        }
      }

    async function availableDividendForClaim() {
        if (apishToken && accounts[0]) {
          const dividends = await apishToken.methods.getAccountDividendsInfo(accounts[0]).call();
          console.log(dividends)
          setAvailableDividends(dividends[5]);
          return dividends[5];
        }
      }

     async function dividend() {
         if (apishToken && accounts[0]) {
           const dividends = await apishToken.methods.getAccountDividendsInfo(accounts[0]).call();
           console.log(dividends)
           setDividends(dividends[4]);
           return dividends[4];
         }
       }

  return (
    <div className="w-full overflow-hidden">
      {showModal && (
        <Modal title="" onClose={() => setShowModal(false)}>
          <div className="text-2xl">
            Failed!! Connect First And Check Your Transaction.
          </div>
        </Modal>
      )}

      <div className="relative w-full z-30">

        <header className="container px-4 mx-auto py-4">
          <div className="flex flex-row justify-between items-center relative">
            <a
              href="https://www.apish.me"
              className="logo flex flex-row items-center"
            >
              <img
                src="/images/full-logo.png"
                width="180"
                className="cursor-pointer"
                alt="APISH"
              />
            </a>

            <Button
              className="flex flex-row justify-right"
              uppercase={false}
              onClick={async () => await init()}
            >
              {loading && <Spinner color="white" size={40}/>}
              {!loading && !accounts && (error !== "" ? error : "CONNECT")}
              {!loading && accounts && (error !== "" ? error : "CONNECTED")}
            </Button>
         </div>
        </header>

        <div className="container mx-auto pb-18 px-2 force-height">
            <div className="grid grid-col-1 md:grid-cols-1 gap-3 mt-5">
                  <Card title="Holding Rewards">
                      <div className="text-center text-white text-2xl mt-4 mx-2" style={{width: "100%"}}>
                          <div style={{display: "inline-block", paddingRight:"25px", paddingLeft:"25px"}}>
                            <span className="text-gray-400 text-lg">
                              Total:{" "}
                            </span>
                            {+(parseFloat(totalDividends) / 1000000000000000000).toFixed(2) || 0} TLOS
                          </div>
                          <div style={{display: "inline-block", paddingRight:"25px", paddingLeft:"25px"}}>
                           <span className="text-gray-400 text-lg">
                             Earned:{" "}
                           </span>
                           {+(parseFloat(dividends) / 1000000000000000000).toFixed(2) || 0} TLOS
                         </div>
                         <div style={{display: "inline-block", paddingRight:"25px", paddingLeft:"25px"}}>
                            <span className="text-gray-400 text-lg">
                              Waiting:{" "}
                            </span>
                            {+(parseFloat(availableDividends) / 1000000000000000000).toFixed(2) || 0} TLOS
                         </div>
                    </div>
                  </Card>
            </div>
            <div className="grid grid-col-1 md:grid-cols-2 gap-3 mt-5">
              <Card title="Total Staked">
                <div className="flex flex-col pt-4 pb-2 text-white">
                  <div className="text-center">
                    <span className="text-white text-5xl">
                      {+(
                        (parseFloat(totalStaked).toFixed(2)) /
                        1000000000
                      ).toFixed(2) || 0}
                    </span>
                  </div>
                  <div className="text-center">
                    {+(
                      (parseFloat(totalStaked) * 100.0) /
                      parseFloat(totalSupply)
                    ).toFixed(2) || 0}
                    % of total supply
                  </div>
                </div>
                <div className="text-gray-400 text-lg font-thin">
                  <ul>
                    <li>
                      Staking Fee:{"  "}
                      <span className="text-white text-2xl">
                        {parseFloat(stakingTax) / 10 || 0} %
                      </span>
                    </li>
                    <li>
                      Unstaking Fee:{"  "}
                      <span className="text-white text-2xl">
                        {parseFloat(unstakingTax) / 10 || 0} %
                      </span>
                    </li>
                    <li>
                      Minimum Stake:{"  "}
                      <span className="text-white text-2xl">
                        {parseInt(minStake) / 1000000000 || 0}
                      </span>
                    </li>
                    <li>
                      All fees will be burned.
                    </li>
                  </ul>
                </div>
              </Card>

              <Card title="Staking Rewards">
                  <div className="flex flex-col">
                    <div className="text-center">
                      <span className="text-white text-5xl">
                        {+(parseFloat(totalRewards) / 1000000000).toFixed(2) || 0}
                      </span>
                    </div>
                    <div className="flex flex-row justify-center">
                      <Button
                        type="submit"
                        className="flex flex-row items-center justify-center w-32"
                        onClick={() => withdrawEarnings()}
                      >
                        {withdrawLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            <span className="w-24">CLAIM</span>{" "}
                          </>
                        )}
                      </Button>
                    </div>
                    <div className="text-center text-white text-2xl mt-4 mx-2">
                      <div>
                          <div>
                            <span className="text-gray-400 text-lg">
                              Balance:{" "}
                            </span>
                            {parseInt(parseInt(balance) / 1000000000) || 0} APISH
                          </div>
                        <div>
                          <span className="text-gray-400 text-lg">
                            Staking Reward:{" "}
                          </span>
                          {parseFloat(stakingRewards) / 1000000000 || 0}
                        </div>
                        <div>
                          <span className="text-gray-400 text-lg">
                            Daily Return:{" "}
                          </span>
                          {parseFloat(dailyROI) / 100 || 0 } %
                        </div>
                        <div>
                          <span className="text-gray-400 text-lg">
                            APR:{" "}
                          </span>
                          {parseFloat(dailyROI) / 100 * 365 || 0 } %
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>

                <Card title="Stake">
                  <div className="flex flex-col pt-4 px-2">
                    <div className="text-center pb-2">
                      <span className="text-lg text-gray-400">
                        Minimum amount needed:{" "}
                      </span>
                      <span className="text-white text-3xl">{parseInt(minStake) / 1000000000 || 0 }</span>
                    </div>
                    <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                      <input
                        type="number"
                        placeholder="Stake Amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="text-white font-extrabold flex-shrink text-2xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                      />
                      <Button
                        onClick={() => stake()}
                        className="flex flex-row items-center w-48 justify-center"
                      >
                        {stakeLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            <span className="w-16">STAKE</span>{" "}
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </Card>

              <Card title="Unstake">
                <div className="flex flex-col pt-4 px-2">
                  <div className="text-center pb-2">
                      <span className="text-lg text-gray-400">
                        Available to unstake:{" "}
                      </span>
                      <span className="text-white text-3xl">{+(parseFloat(stakes) / 1000000000).toFixed(2) || 0}</span>
                    </div>
                  <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                    <input
                      type="number"
                      placeholder="Un-stake Amount"
                      value={unstakeAmount}
                      onChange={(e) => setUnstakeAmount(e.target.value)}
                      className="text-white font-extrabold flex-shrink text-2xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                    />
                    <Button
                      onClick={() => unstake()}
                      className="flex flex-row items-center w-48 justify-center"
                    >
                      {unstakeLoading ? (
                        <Spinner size={30} />
                      ) : (
                        <>
                          <span className="w-24">UNSTAKE</span>
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
