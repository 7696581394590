import React from "react";
import cx from "classnames";

import styled from "styled-components";

export const StyledButton = styled.button`

  -webkit-appearance: initial;
   overflow: visible;
   position: -webkit-sticky;
   position: sticky;
   z-index: 2;
   display:inline-block;
   font-size: 20px;
   border:2px solid transparent;
   letter-spacing: .5px;
   line-height: inherit;
   border-radius: 0;
   text-transform:capitalize;
   width: auto;
   font-family: 'Montserrat', sans-serif;
   font-weight: bold;
   -webkit-transition: all .5s ease;
   -o-transition: all .5s ease !important;
   transition: all .5s ease !important;

  /* Transparent button */
  background: transparent;
  border-color: #ffffff;
  color: #ffffff;
  text-decoration: none;
  :hover {
    background: #ffffff !important;
    border-color: #ffffff;
    color: #232728 !important
  }

  /* button size */
  font-size:16px;
  padding: 5px 20px;
  line-height: 1.8em;
  margin: 5px;

  border-radius: 50px
`;


export default ({
  color = "white",
  bgColor = "primary",
  showBorder = false,
  onClick,
  className,
  children,
  uppercase = true,
}) => {
  return (
    <StyledButton
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};
